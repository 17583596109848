// app/routes/home.tsx
import {
  HeadersFunction,
  json,
  LoaderFunctionArgs,
  MetaFunction,
  redirect,
} from '@remix-run/node';
import { useLoaderData } from '@remix-run/react';
import { createRef, useState } from 'react';
import { FaCircleCheck } from 'react-icons/fa6';
import { Footer } from '~/components/Footer';
import { Header } from '~/components/Header';
import '~/styles/home.css';
import analyticsMix from '../assets/analyticsmix.png';
import bskLogo from '../assets/bsklogo.png';
import dynamicInsights1 from '../assets/dynamicinsights_1.png';
import dynamicInsights2 from '../assets/dynamicinsights_2.png';
import falseVideo from '../assets/falsevideo.png';
import deviceImage from '../assets/herosection.png';

import analyticsMix_webp from '../assets/analyticsmix.webp';
import bskLogo_webp from '../assets/bsklogo.webp';
import dynamicInsights1_webp from '../assets/dynamicinsights_1.webp';
import dynamicInsights2_webp from '../assets/dynamicinsights_2.webp';
import falseVideo_webp from '../assets/falsevideo.webp';
import deviceImage_webp from '../assets/herosection.webp';

export const headers: HeadersFunction = () => ({});

export const meta: MetaFunction = () => {
  return [
    { title: 'Expressed Behaviour Analytics Platform' },
    {
      name: 'description',
      content: 'Welcome to the Expressed Behaviour Analytics Platform!',
    },
  ];
};

export async function loader({ request, params }: LoaderFunctionArgs) {
  return json('', {
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export async function action(/*request*/) {
  return redirect('/home/');
}
export const pricing = [
  {
    title: 'Trial',
    subtitle: 'Explore the platform for FREE - no credit card required',
    feature: [
      'Upload and process 5 videos of up to five minutes in length',
      'One user',
      'Standard dashboards only',
      'Email access for support/questions',
      'Entitlement ends 14 days after first video upload',
    ],
    link: '',
    priceMonthly: null,
    priceYearly: null,
    cta: 'Start Free 14 Day Trial',
    slug: 'trial',
    monthlyValue: null,
    yearlyValue: null,
    seats: 1,
  },
  {
    title: 'Basic',
    subtitle: '',
    feature: [
      'Upload 10 videos per month of up to five minutes in length',
      'One user',
      'Standard dashboards and drill-down',
      'Email access for support/questions',
    ],
    link: 'pricing?tier=basic',
    priceMonthly: '£90 per month',
    priceYearly: '£76.50 per month',
    cta: 'Get Basic',
    slug: 'basic',
    monthlyValue: 90,
    yearlyValue: 76.5,
    seats: 1,
  },

  {
    title: 'Pro',
    subtitle: '',
    feature: [
      'Upload 30 videos of up to five minutes in length',
      'Up to 5 users',
      'Standard dashboards and drill-down',
      'Email access for support/questions',
    ],
    link: 'pricing?tier=pro',
    priceMonthly: '£425 per month',
    priceYearly: '£362 per month',
    cta: 'Get Pro',
    slug: 'pro',
    monthlyValue: 425,
    yearlyValue: 362,
    seats: 5,
  },
  {
    title: 'Enterprise',
    subtitle: '',
    feature: [
      'Single enterprise instance with flexible number of video uploads',
      'Single Sign-on',
      'Variable video length',
      'Multiple users',
      'Bespoke dashboards and drill-down',
      'Designated account management and support',
    ],
    link: 'mailto:enquire@blueskeye.com',
    priceMonthly: 'Price on Application',
    priceYearly: 'Price on Application',
    cta: 'Enquire Now',
    slug: 'enterprise',
    monthlyValue: null,
    yearlyValue: null,
  },
];

export default function Home() {
  const resp = useLoaderData<typeof loader>();
  const [billingType, setBillingType] = useState('yearly');

  const questions = [
    {
      title: 'Do I get free updates?',
      content:
        'With our Pro subscription, yes you can! This allows you to share your project with them as well as analytics and insights of each.',
      linktext: 'Click to learn more',
      linkref: '',
    },
    {
      title: 'Do I get free updates?',
      content:
        'With our Pro subscription, yes you can! This allows you to share your project with them as well as analytics and insights of each.',
      linktext: 'Click to learn more',
      linkref: '',
    },
    {
      title: 'Do I get free updates?',
      content:
        'With our Pro subscription, yes you can! This allows you to share your project with them as well as analytics and insights of each.',
      linktext: 'Click to learn more',
      linkref: '',
    },
    {
      title: 'Do I get free updates?',
      content:
        'With our Pro subscription, yes you can! This allows you to share your project with them as well as analytics and insights of each.',
      linktext: 'Click to learn more',
      linkref: '',
    },
  ];

  const pricingRef = createRef<HTMLDivElement>();

  return (
    <div>
      <div id="home">
        <Header pricingRef={pricingRef} />
        <div id="homeContent">
          <div id="homeTitle">
            <div className="textContent">
              <div className="missionLogo">
                <img
                  src={bskLogo_webp}
                  alt="Blueskeye logo"
                  style={{ width: '100%' }}
                  onError={(e) => {
                    e.currentTarget.onerror = null; // Prevents infinite loop if the fallback also fails
                    e.currentTarget.src = bskLogo; // Fallback to the original JPG or PNG
                  }}
                />
              </div>
              <h1 className="ctaHeader">
                Actionable, objective, business insights about how your
                customers feel using your product, service or content.
              </h1>
              <p className="lightText">
                By passively monitoring facial movements we highlight aspects of
                the interactions that cause changes in emotional response,
                providing unique insights so you can better understand
                customers' feelings and sentiment during the engagement.
              </p>
              {/* <div className="buttonContainer">
                 <button className="sideButton">Get started for free</button> 
                 <button className="demoButton">
                  <CgPlayButtonO fontSize={30} /> View Demo
                </button> 
              </div> */}
            </div>
            <img
              src={deviceImage_webp}
              alt="Insights"
              className="titleImage"
              onError={(e) => {
                e.currentTarget.onerror = null; // Prevents infinite loop if the fallback also fails
                e.currentTarget.src = deviceImage; // Fallback to the original JPG or PNG
              }}
            />
          </div>
          <div className="card-section">
            <div className="card card-1">
              <div className="text-container-1">
                <p>Easy to use</p>
              </div>
              <div className="arrow">&rarr;</div>
            </div>
            <div className="card card-2">
              <div className="text-container-2">
                <p>Videos are uploaded to our cloud-based platform</p>
              </div>
              <div className="arrow">&rarr;</div>
            </div>
            <div className="card card-3">
              <div className="text-container-3">
                <p>
                  Analysis results may be viewed online or by downloading
                  searchable dashboard reports
                </p>
              </div>
            </div>
          </div>
          <hr className="midwide"></hr>
        </div>

        {/* <Carousel /> */}
        <div className="container">
          <div className="listSection">
            <div className="listContainer">
              <div className="listItem">
                <div className="numberCircle">1</div>
                <div className="textContent">
                  <h3 className="title">
                    Discover what works and what doesn't
                  </h3>
                  <p className="description">
                    Identify the key emotional triggers in a piece of content,
                    ensure they work as anticipated and deliver on your business
                    goals.
                  </p>
                </div>
              </div>
              {/* <div className="divider"></div> */}
              <div className="listItem">
                <div className="numberCircle">2</div>
                <div className="textContent">
                  <h3 className="title">Save time and money</h3>
                  <p className="description">
                    Augment the current information you obtain with a richer
                    picture of how subjects feel. Identify the most relevant
                    moments during the engagement so you can focus your
                    attention on critical events in the user interaction.
                  </p>
                </div>
              </div>
              {/* <div className="divider"></div> */}
              <div className="listItem">
                <div className="numberCircle">3</div>
                <div className="textContent">
                  <h3 className="title">
                    Obtain a full picture of the emotional responses to your
                    content
                  </h3>
                  <p className="description">
                    Aggregate and analyse multiple videos quickly and easily.
                    Content is monitored continuously, reducing recency bias and
                    providing actionable insights rather than a generalised
                    assessment.
                  </p>
                </div>
              </div>
              {/* <div className="divider"></div> */}
              <div className="listItem">
                <div className="numberCircle">4</div>
                <div className="textContent">
                  <h3 className="title">
                    Richer insights from our data science
                  </h3>
                  <p className="description">
                    Identify patterns across numerous videos including responses
                    to triggers, demographic uniquities and the impact of social
                    interactions.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="imageSection">
            <img
              src={analyticsMix_webp}
              alt={'Description'}
              className="leftImage"
              style={{ width: '75%', height: 'auto' }}
              onError={(e) => {
                e.currentTarget.onerror = null; // Prevents infinite loop if the fallback also fails
                e.currentTarget.src = analyticsMix; // Fallback to the original JPG or PNG
              }}
            />
          </div>
        </div>

        {/* <p className="useCaseFooter">
          If you wish to discuss a particular use case please{' '}
          <a href="mailto:support@blueskeye.com">get in touch</a>
        </p> */}

        <hr className="midwide"></hr>
        <div className="videoSection">
          <h3>
            Identifying the most impactful content across multiple personas
          </h3>
          <img
            src={falseVideo_webp}
            alt="Demo video"
            style={{ width: '50%' }}
            onError={(e) => {
              e.currentTarget.onerror = null; // Prevents infinite loop if the fallback also fails
              e.currentTarget.src = falseVideo; // Fallback to the original JPG or PNG
            }}
          />
        </div>
        <hr className="midwide"></hr>
        <div className="contentContainer">
          <p className="blueParagraph">
            Our emotion AI technology is suitable for a range of user testing
            scenarios; these include response to an advert or online content;
            user surveys and use of IT based products and services.
          </p>
          <button className="sideButton">Get started for free</button>
        </div>
        <div className="dynamicinsightssection">
          <h3>Dynamic Insights</h3>
          <img
            src={dynamicInsights1_webp}
            alt="Summary metrics"
            style={{ width: '50%' }}
            onError={(e) => {
              e.currentTarget.onerror = null; // Prevents infinite loop if the fallback also fails
              e.currentTarget.src = dynamicInsights1; // Fallback to the original JPG or PNG
            }}
          />
          <img
            src={dynamicInsights2_webp}
            alt="Live metrics"
            style={{ width: '50%' }}
            onError={(e) => {
              e.currentTarget.onerror = null; // Prevents infinite loop if the fallback also fails
              e.currentTarget.src = dynamicInsights2; // Fallback to the original JPG or PNG
            }}
          />
        </div>
      </div>
      <div ref={pricingRef} id="pricingSection">
        <h3 style={{ color: 'black' }}>Pricing & Plans</h3>
        <p className="pricingSubtitle">
          From individual users to small and global teams. Explore the best
          option for you
        </p>
        <div className="toggle-container">
          <button
            className={`toggle-button ${billingType === 'monthly' ? 'active' : ''}`}
            onClick={() => setBillingType('monthly')}
          >
            Bill Monthly
          </button>
          <button
            className={`toggle-button ${billingType === 'yearly' ? 'active' : ''}`}
            onClick={() => setBillingType('yearly')}
          >
            Bill Yearly
          </button>
        </div>
        <div className="rowArrangement" id="pricingRow">
          {pricing.map((e, i) => {
            return (
              <div
                className={
                  i == 1 || i == 2
                    ? 'pricingColumn tall'
                    : 'pricingColumn medium'
                }
                key={`pricing-${e.title}`}
              >
                <div className="pricingHeader textAlignCenter">
                  <text className="pricingTitle boldText">{e.title}</text>
                  <div className="pricingSubtitleContainer">
                    <p
                      className={
                        i == 1 || i == 2
                          ? 'pricingSubtitle boldText highlight'
                          : 'pricingSubtitle boldText'
                      }
                    >
                      {e.subtitle}
                    </p>
                  </div>
                </div>
                <div
                  className={
                    i == 1 || i == 2
                      ? 'featureColumn highlight'
                      : 'featureColumn'
                  }
                >
                  <table className="featureTable">
                    <tbody>
                      {e.feature.map((feature, i) => (
                        <tr
                          key={`${e.title}-${feature}`}
                          className={
                            i === e.feature.length - 1 ? 'noBottomBorder' : ''
                          }
                        >
                          <td className="featureCheckColumn">
                            <FaCircleCheck className="featureCheck" />
                          </td>
                          <td className="featureTextColumn">
                            <span>{feature}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="price boldText">
                    {((billingType == 'yearly' && e.priceYearly) ||
                      (billingType == 'monthly' && e.priceMonthly)) && (
                        <text className="blackText">
                          {billingType == 'yearly'
                            ? e.priceYearly
                            : e.priceMonthly}
                        </text>
                      )}

                    {/* {i == 1 && (
                      <>
                        <br></br>
                        <button className="pricingButton highlight noAction">
                          Save £50 a year
                        </button>
                      </>
                    )} */}
                  </div>
                </div>
                <div className="pricingButtonContainer">
                  <button
                    className={
                      e.title == 'Basic' || e.title == 'Pro'
                        ? 'pricingButton highlight'
                        : 'pricingButton'
                    }
                    onClick={() => {
                      if (e.title == 'Trial') {
                        const signupButton =
                          document.getElementById('signupButton');
                        signupButton?.click();
                      } else window.location.href = e.link + "&billing=" + billingType;
                    }}
                  >
                    {e.cta}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        {/* <div className="enterpriseArrangement">
          <div className="enterpriseColumn" key={`pricing-Enterprise`}>
            <text
              className="pricingTitle white boldText"
              style={{ textAlign: 'center', marginBottom: '20px' }}
            >
              Enterprise
            </text>
            <text className="pricingSubtitle white">
              For large teams requiring extensive analysis.
            </text>

            <button
              style={{ marginBottom: '20px', width: '200px' }}
              className="pricingButton highlight"
              onClick={() => {
                return redirect('#');
              }}
            >
              Contact us
            </button>
          </div>
        </div> */}
        {/* <div className="questionsContainer" id="questions">
          {questions.map((question, index) => {
            
            if (index % 2 === 0) {
              return (
                <div className="questionRow" key={index}>
                  
                  <div className="questionColumn">
                    <BsFillQuestionCircleFill style={{ color: '#1E3871' }} />{' '}
                    <b>{question.title}</b>
                    <p className="questionContent">{question.content}</p>
                    <a href={question.linkref}>{question.linktext}</a>
                  </div>
                  
                  {questions[index + 1] && (
                    <div className="questionColumn">
                      <BsFillQuestionCircleFill style={{ color: '#1E3871' }} />{' '}
                      <b>{questions[index + 1].title}</b>
                      <p className="questionContent">
                        {questions[index + 1].content}
                      </p>
                      <a href={questions[index + 1].linkref}>
                        {questions[index + 1].linktext}
                      </a>
                    </div>
                  )}
                </div>
              );
            }
            return null; // Return null for odd indexes to avoid rendering extra rows
          })}
          <p>
            Haven't got your answer?{' '}
            <a href="mailto:support@blueskeye.com">Contact our support now</a>
          </p>
        </div> */}
      </div>

      <div className="missionSection">
        <hr className="midwide"></hr>
        <h1>
          BLUESKEYE AI are global leaders in using face data to understand
          expressed human behaviour. We have validated that our technology is
          better than the human eye for assessing changes in emotional
          responses.
        </h1>
        <p style={{ fontSize: '1.25vw' }}>
          In addition to commercial customers in the consumer, pharmaceutical
          and automotive spaces our technology is progressing through clinical
          trials to assure the UK health regulatory authorities of its efficacy.
        </p>
        <p style={{ fontSize: '1.25vw' }}>
          BLUESKEYE AI uses continuous multidimensional approach to continuously
          measure people's emotion throughout their experience of using your
          product or consuming your content. This means we can measure
          transitions between emotional state throughout. This better fits with
          the real human experience and provides richer and more nuanced
          insights.
        </p>
        <p style={{ fontSize: '1.25vw' }}>
          The technology has been in development for over 20 years, initially at
          the University of Nottingham and with Blueskeye since it was spun out
          of the University in 2019.
        </p>
        <p style={{ fontSize: '1.25vw' }}>
          The technology has been trained using our data bank of over XX billion
          data items and been proven to work effectively irrespective of
          apparent age, gender and ethnicity.
        </p>
        <hr className="midwide"></hr>
      </div>

      <div className="footerSection">
        {/* <div className="contactSection">
          <div className="contactFormContainer">
            <form className="contactForm">
              <div className="inputGroup">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  required
                  className="inputField"
                />
              </div>
              <div className="inputGroup">
                <label htmlFor="industry">Industry:</label>
                <input
                  type="text"
                  id="industry"
                  name="industry"
                  required
                  className="inputField"
                />
              </div>
              <button type="submit" className="startTrialButton">
                Start Free Trial
              </button>
              <p>
                or <a href="#">Log In</a>
              </p>
            </form>
          </div>
          <div className="linksContainer">
            <div className="column">
              <h3 className="columnTitle">Pricing</h3>
              <ul className="linkList">
                <li>
                  <a className="footerLink" href="#link1">
                    Billing
                  </a>
                </li>
                <li>
                  <a className="footerLink" href="#link2">
                    FAQs
                  </a>
                </li>
                <li>
                  <a className="footerLink" href="#link3">
                    Compare Features
                  </a>
                </li>
              </ul>
            </div>
            <div className="column">
              <h3 className="columnTitle">Help Centre</h3>
              <ul className="linkList">
                <li>
                  <a className="footerLink" href="#link4">
                    Get support
                  </a>
                </li>
                <li>
                  <a className="footerLink" href="#link5">
                    Video tutorials
                  </a>
                </li>
                <li>
                  <a className="footerLink" href="#link6">
                    Live chat
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
        {/* <hr className="midwide nopad"></hr> */}
        <Footer />
      </div>
    </div>
  );
}
