import { useLocation, useNavigate } from '@remix-run/react';
import { onAuthStateChanged, User } from 'firebase/auth';
import { RefObject, useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { auth } from '~/utils/Auth/firebase-service';
import { getProfile } from '~/utils/ServerRequest';
import '../styles/home.css';
import { PopupModal } from './PopupModal';

export const Header = ({
  pricingRef,
}: {
  pricingRef?: RefObject<HTMLDivElement>;
}) => {
  const [signUp, setSignUp] = useState(false);
  const [login, setLogin] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [allowance, setAllowance] = useState<number>();
  const location = useLocation();
  const [profile, setProfile] = useState<Profile | null>(null);

  interface Profile {
    message: string,
    allowance: number,
    projects: [],
    signupComplete: boolean,
    subscription: string
  }

  useEffect(() => {
    // Ensures user is authorised before initialising variables.
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        setLogin(false);
        setSignUp(false);

        const prof = await getProfile();
        setProfile(prof);

        // checks if user has completed information form
        if (!prof.postSignupFormCompleted) navigate('/informationForm');

        setAllowance(prof.allowance);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const toggleSignUp = () => {
    setSignUp(!signUp);
  };

  const toggleLogin = () => {
    setLogin(!login);
  };

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => setUser(null))
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  };

  let navigate = useNavigate();

  return (
    <header className="header">
      {/* <div className="logoHeader">
        <Link to="/home">
          <img
            src={logo_webp}
            width={270}
            id="popupLogo"
            alt="BLUESKEYE AI Logo"
            onError={(e) => {
              e.currentTarget.onerror = null; // Prevents infinite loop if the fallback also fails
              e.currentTarget.src = logo; // Fallback to the original JPG or PNG
            }}
          />
        </Link>
      </div> */}

      {location.pathname !== '/home' &&
        location.pathname.split('/')[1] !== 'analytics' &&
        location.pathname.split('/')[1] !== 'informationForm' && (
          <button
            className="clearButton"
            onClick={() => {
              console.log(location.pathname.split('/')[1]);
              navigate('/home');
            }}
          >
            Return to Homepage
          </button>
        )}
      {location.pathname === '/home' && (
        <>
          <div className="rowArrangement">
            <button
              className="headerButton"
              onClick={() => {
                window.location.href = 'mailto:enquire@blueskeye.com';
              }}
            >
              Enquire Now
            </button>
            <button
              className="headerButton highlight"
              onClick={() => navigate('/useCases')}
            >
              Use Cases
            </button>
            <button
              className="headerButton highlight"
              onClick={() => {
                pricingRef?.current?.scrollIntoView({ behavior: 'smooth' });
              }}
            >
              Pricing
            </button>
          </div>
          {/* <div className="centeredButtons">
            <button className="linkButton">Pricing</button>
            <button className="linkButton">Support</button>
            <button className="linkButton">Contact</button>
          </div> */}
          <div className="rightButtons">
            {user === null ? (
              <>
                <button className="headerButton" onClick={toggleLogin}>
                  Log In
                </button>

                <button
                  id="signupButton"
                  className="headerButton highlight"
                  onClick={toggleSignUp}
                >
                  {/* Sign Up <FaArrowRight /> */}
                  Free Trial
                </button>
              </>
            ) : (
              <>
                <button
                  className="headerButton highlight"
                  onClick={() => navigate('/uploads')}
                >
                  My Projects
                </button>

                <button className="headerButton" onClick={handleLogout}>
                  Logout
                </button>
              </>
            )}
          </div>
        </>
      )}

      {loading ||
        (location.pathname === '/uploads/projects' && (
          <div className="rightButtons">
            {user === null ? (
              <>
                <button className="headerButton" onClick={toggleLogin}>
                  Log In
                </button>

                <button
                  className="headerButton highlight"
                  onClick={toggleSignUp}
                >
                  Sign Up <FaArrowRight />
                </button>
              </>
            ) : (
              <div className="alignItemsCenter">
                <button
                  className="clearButton headerButtonText"
                  onClick={() => { }}
                >
                  {allowance} tokens
                </button>
                <p>signed in as: </p>
                <span style={{ display: "flex", alignItems: "left", gap: "8px" }}>
                  <button
                    className="clearButton"
                    onClick={() => { }}
                  >
                    {user.displayName}
                  </button>
                  <div className={'headerButtonText tier-' + profile!.subscription.toLowerCase()}>
                    {profile!.subscription}
                  </div>
                </span>
                <button className="clearButton" onClick={handleLogout}>
                  Logout
                </button>
              </div>
            )}
          </div>
        ))}

      {signUp && (
        <PopupModal
          mode={'signUp'}
          toggle={toggleSignUp}
          switchModal={() => {
            toggleSignUp();
            toggleLogin();
          }}
        />
      )}
      {login && (
        <PopupModal
          mode={'login'}
          toggle={toggleLogin}
          switchModal={() => {
            toggleSignUp();
            toggleLogin();
          }}
        />
      )}
    </header>
  );
};
